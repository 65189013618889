/**
 * Created by ashio on 2017-06-16.
 */
(function(){
    var inLazyLoad = false;
    var itemPerLoad = 6;
    var container = document.querySelector('.product-item-container');
    var placeholderPrefixId = 'placeholder-';
    var placeholderShown = false;
    var waypoints = [];
    var lazyLoadEnabled = true;

    var nextRequestUrl = window.location.href;

    var ajaxErrorCount = 0;
    var pageCount = 0;

    function generatePlaceholders(count, startIndex){
        count = count || itemPerLoad;
        startIndex = startIndex || 0;
        var placeholderWaypoint = document.createElement('div');
        placeholderWaypoint.setAttribute('id', 'placeholder-waypoint');
        placeholderWaypoint.classList.add('waypoint');
        container.appendChild(placeholderWaypoint);
        for(var i = startIndex; i < startIndex + count; i++){
            var template = document.getElementById('template-product-item');
            var clone = document.importNode(template.content, true);

            clone.querySelector('.product-item').setAttribute('id', placeholderPrefixId + i);
            clone.querySelector('.product-item').classList.add('unused-placeholder');
            container.appendChild(clone);
        }
        placeholderShown = true;
    }

    function removeRemainingPlaceholders(){
        var placeholderWaypoint = document.getElementById('placeholder-waypoint');
        if(placeholderWaypoint) placeholderWaypoint.parentElement.removeChild(placeholderWaypoint);
        //  Apple Safari WebKit (iPhone/macOS) doesn't like selector with RegEx
        //var remainingPlaceholders = document.querySelectorAll('[id^=placeholder-');
        var remainingPlaceholders = document.querySelectorAll('.unused-placeholder');
        for(var i = 0; i < remainingPlaceholders.length; i++){
            var placeholder = remainingPlaceholders[i];
            placeholder.parentElement.removeChild(placeholder);
        }
        placeholderShown = false;
    }

    var debouncedLazyLoad = _.debounce(lazyLoad, 500, true);

    function scrollEvents(){
        waypointChecking();
        if(lazyLoadEnabled) debouncedLazyLoad();
    }

    function getScrollTop(){
        var scrollingElementValue = null;
        var documentBodyValue = null;
        var documentElementValue = null;
        var windowScrollY = null;
        if (document.scrollingElement) scrollingElementValue = document.scrollingElement.scrollTop;
        if (document.body) documentBodyValue = document.body.scrollTop;
        if (window) windowScrollY = window.scrollY;
        if (document.documentElement) documentElementValue = document.documentElement.scrollTop;
        return scrollingElementValue || documentElementValue || documentBodyValue || windowScrollY || 0;
    }

    function waypointChecking(){
        var currentViewportTop = getScrollTop();
        var activatedWaypoint;
        for(var i = 0; i < waypoints.length; i++){
            var waypoint = waypoints[i];
            var offset = 80;
            if(currentViewportTop + offset > waypoint.offsetTop)
                activatedWaypoint = waypoint;
        }
        if(activatedWaypoint)
            window.history.replaceState({}, '', activatedWaypoint.getAttribute('url'));
    }

    function enableUnlimitedProducts(){
        pageCount = undefined;
        var button = document.querySelector('.load-more-button');
        button.parentNode.removeChild(button);
        lazyLoad();
        //bindScrollListener();
        lazyLoadEnabled = true;
    }

    function addLoadMoreButton(){
        var container = document.querySelector('.product-item-info');
        var template = document.getElementById('load-more-button');
        var clone = document.importNode(template.content, true);
        var button = clone.querySelector('.load-more-button');
        button.addEventListener('click', enableUnlimitedProducts);
        container.appendChild(clone);
    }

    function addNetworkErrorMessage(){
        if(document.querySelector('.network-error')) return;
        var container = document.querySelector('.product-item-info');
        var template = document.getElementById('network-error-message');
        var clone = document.importNode(template.content, true);
        var button = clone.querySelector('.try-again-button');
        button.addEventListener('click', lazyLoad);
        container.appendChild(clone);
    }

    function removeNetworkErrorMessage(){
        var networkErrorMessage = document.querySelector('.network-error');
        if(networkErrorMessage){
            networkErrorMessage.parentNode.removeChild(networkErrorMessage);
        }
    }

    function lazyLoad(){
        var offset = 160;
        var containerBottom = (container.offsetTop + container.offsetHeight);
        var currentViewportBottom = getScrollTop() + window.innerHeight + offset;

        if(currentViewportBottom < containerBottom) return;
        if(inLazyLoad) return;

        if(!placeholderShown) generatePlaceholders();
        // var containsQueryString = nextRequestUrl.indexOf('?') !== -1;
        // var antiCache = (containsQueryString ? '&' : '?') + 'cache=' + Date.now();
        // var antiCacheRequestUrl = nextRequestUrl + antiCache;
        inLazyLoad = true;
        axios
            .get(nextRequestUrl, {
                headers: {'Cache-Control': 'no-cache'}
            })
            .then(function(result){
                var content     = result.data;
                var resultCount = content.count;
                var products    = content.products;
                var return_url  = content.return_url;
                var next_url    = content.next_url;
                var prev_url    = content.prev_url;

                nextRequestUrl = content.next_url;

                //  Check if returned value is null, if yes,
                //  release all eventlisteners
                if(next_url === null) lazyLoadEnabled = false;

                if(resultCount > itemPerLoad){
                    var extras = resultCount - itemPerLoad;
                    generatePlaceholders(extras, itemPerLoad);
                }

                for(var i = 0; i < resultCount; i++){
                    var product = products[i];
                    var placeholderId = placeholderPrefixId + i;
                    var placeholder = document.getElementById(placeholderId);

                    var image = placeholder.querySelector('.picture img');
                    image.setAttribute('src', product.optimized_image_full_path);
                    var name = placeholder.querySelector('.name');

                    name.innerText = product.name || product.product_id;

                    var price = placeholder.querySelector('.price');
                    var priceAmount = price.querySelector('.amount');

                    var isInPromo = product.has_display_original_price;
                    priceAmount.innerText = Number(product.display_price);

                    if(isInPromo){
                        price.classList.add('discounted');
                        var originalPriceContainer = document.createElement('span');
                        originalPriceContainer.classList.add('original-price');

                        var originalCurrency = document.createElement('span');
                        originalCurrency.classList.add('currency');
                        originalCurrency.innerText = "NT$";

                        var originalAmount = document.createElement('span');
                        originalAmount.classList.add('amount');
                        originalAmount.innerText = Number(product.display_original_price);
                        originalPriceContainer.appendChild(originalCurrency);
                        originalPriceContainer.appendChild(originalAmount);
                        price.appendChild(originalPriceContainer);
                    }

                    placeholder.setAttribute('href', product.product_shim_page_url);

                    placeholder.classList.remove('product-item-loading');

                    var loader = placeholder.querySelector('.product-item-progress');
                    placeholder.removeChild(loader);

                    placeholder.removeAttribute('id');
                    placeholder.classList.remove('unused-placeholder');

                    Ellipsis.ellipseByElement(name);
                }

                var waypoint = document.getElementById('placeholder-waypoint');
                waypoint.setAttribute('url', return_url);
                waypoint.setAttribute('id', 'waypoint-' + Date.now().toString(16));
                waypoints.push(waypoint);

                if(pageCount !== undefined){
                    pageCount++;
                    if(pageCount === 5){
                        lazyLoadEnabled = false;
                        addLoadMoreButton();
                    }
                }
                if(ajaxErrorCount >= 3){
                    ajaxErrorCount = 0;
                    removeNetworkErrorMessage();
                    bindScrollListener();
                }

                waypointChecking();
                removeRemainingPlaceholders();
                inLazyLoad = false;
            })
            .catch(function(exception){
                if(exception.response){
                    console.log(exception);
                }
                else{
                    ajaxErrorCount++;
                    if(ajaxErrorCount >= 3){
                        addNetworkErrorMessage();
                        lazyLoadEnabled = false;
                    }
                }

                removeRemainingPlaceholders();
                inLazyLoad = false;
            });
    }

    var handleScroll = _.debounce(scrollEvents, 50);

    var listenerAttachment = ['scroll', 'touchmove', 'wheel', 'touchend'];
    function bindScrollListener() {
        for(var i = 0; i < listenerAttachment.length; i++){
            window.addEventListener(listenerAttachment[i], handleScroll);
        }
    }

    function unbindScrollListener(){
        for(var i = 0; i < listenerAttachment.length; i++){
            window.removeEventListener(listenerAttachment[i], handleScroll);
        }
    }

    //BACKLOG: Check if the window cannot be scrolled, then load more via ajax
    handleScroll();
    bindScrollListener();
}());